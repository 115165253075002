import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import config from '../plugins/firebase';

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const getFirestore = () => firebase.firestore();

const getStorage = bucket => {
  if (bucket) {
    return firebase.app().storage(bucket);
  }
  return firebase.storage();
};

const getTimestamp = () => firebase.firestore.FieldValue.serverTimestamp();

const timestamp = (createdAt = true, updatedAt = true) => {
  const res = {};
  if (createdAt) {
    res.createdAt = getTimestamp();
  }
  if (updatedAt) {
    res.updatedAt = getTimestamp();
  }
  return res;
};

export {
  config,
  getTimestamp,
  getFirestore,
  getStorage,
  getTimestamp as getCurrentTimestamp,
  timestamp
};
