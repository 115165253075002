import firebase from 'firebase/app';
import 'firebase/analytics';
import config from '../config';

const firebaseConfig = config.firebaseConfig();

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

firebase.analytics();
export default firebaseConfig;
